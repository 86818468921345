<template>
	<div class="container">
		<svg xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 24 24" 
			fill="none" 
			stroke="currentColor" 
			stroke-width="2" 
			stroke-linecap="round" 
			stroke-linejoin="round" 
			class="feather feather-alert-triangle">
				<path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
				<line x1="12" y1="9" x2="12" y2="13"></line>
				<line @click="activate" x1="12" y1="17" x2="12.01" y2="17"></line>
		</svg>

		<h1>Coming soon</h1>

		<p>New social media network&trade; dedicated for gamers and esports fans is under construction.</p>

		<p>We will be live in a short time.</p>

		<div class="contact">
			<h2>Wanna get in touch?</h2>
			<a href="mailto: sebastian@efan.gg">E-mail link</a>
		</div>
	</div>
</template>


<script>
	export default {
		name: "ComingSoon",
		methods: {
			activate() {
				// Set a secret code for this version, change it here and in router route guard to force users to click the secret button again
				localStorage.setItem("secret", "kjsdhfguiost2389475zuisdfg");
				this.$router.push({path: "/landing"});
			}
		}
	}
</script>


<style lang="scss" scoped>
	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		width: 100vw;
		height: 100vh;

		text-align: center;

		@include gradient;

		svg {
			height: 250px;
			width: 250px;
		}
		h1 {
			font-size: 4em;
		}

	}
</style>